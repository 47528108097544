"use client";

import { QueryResult } from "@apollo/client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import styles from "./Hero.module.scss";
import FavoriteRoomCarousel from "@/common/partials/RoomCarousel/FavoriteRoomCarousel";
import carouselStyles from "@/common/partials/RoomCarousel/RoomCarousel.module.scss";
import ViewHistoryCarousel from "@/common/partials/RoomCarousel/ViewHistoryCarousel";
import PropertySearchBar from "@/common/partials/SearchBar/PropertySearchBar";
import Conditions from "@/pages/list/Logic/Condition/Conditions";
import { SavedSearch } from "~/db/models/SavedSearch";
import { SearchHistory } from "~/db/models/SearchHistory";
import {
  useListPageRoomCountQuery,
  Exact,
  QuerySearchRoomsWhereWhereConditions,
  ConditionColumn,
  SqlOperator,
} from "~/generated/graphql";
import { ListPageRoomCountQuery as ListPageRoomCountQueryType } from "~/generated/graphql";
import dayjs from "~/lib/dayjs";
import { WheresConverter } from "~/queries/converters/listPageQueries/WheresConverter";
import { addParameter, removeParameters } from "~/utils/urlUtil";
import beginnerMark from "/public/static/images/top-images/beginner-mark.png";

const pastCriteriaSearchButton = (
  criteria: SearchHistory | SavedSearch,
  label: string,
  newRoomCount?: QueryResult<
    ListPageRoomCountQueryType,
    Exact<{
      where?: QuerySearchRoomsWhereWhereConditions;
    }>
  >,
) => {
  const newRoomCountLoaded =
    newRoomCount && !newRoomCount.loading && newRoomCount.data.search_rooms.paginatorInfo.total > 0;

  return (
    <Link
      className={`${styles.pastCriteria} ${newRoomCountLoaded ? styles.newArrival : ""} ${
        styles.control
      }`}
      href={addParameter(removeParameters(criteria.path, ["o"]), "o", "arrived")}
      prefetch={false}
    >
      <p>
        {label}で探す
        {newRoomCountLoaded && (
          <span className={styles.small}>
            {" "}
            (新着{newRoomCount.data.search_rooms.paginatorInfo.total}件)
          </span>
        )}
      </p>
      <span className={`${styles.small} ${styles.textOverflow}`}>
        {Object.values(criteria.conditions).join("/")}
      </span>
    </Link>
  );
};

const Hero: React.FunctionComponent = () => {
  const [newestSavedSearch, setNewestSavedSearch] = useState<SavedSearch>(null);
  const [newestSearchHistory, setNewestSearchHistory] = useState<SearchHistory>(null);

  // 最も新しい保存条件を取得
  const getNewestSavedSearch = async () => {
    setNewestSavedSearch(await SavedSearch.getNewest());
  };

  // 最も新しい検索履歴を取得
  const getNewestSearchHistory = async () => {
    setNewestSearchHistory(await SearchHistory.getNewest());
  };

  useEffect(() => {
    getNewestSavedSearch();
    getNewestSearchHistory();
  }, []);

  const pastCriteria = newestSavedSearch ?? newestSearchHistory;
  const query = [
    ...Array.from(
      new URLSearchParams(
        pastCriteria?.path?.substring(pastCriteria?.path?.indexOf("?")),
      ).entries(),
    ),
  ].reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});

  const newRoomCountResult = useListPageRoomCountQuery({
    fetchPolicy: "no-cache",
    variables: {
      where: {
        AND: [
          WheresConverter.listPageWhere(Conditions.fromQuery(query)),
          {
            column: ConditionColumn.RoomCreatedAt,
            operator: SqlOperator.Gt,
            value: dayjs().startOf("day").subtract(7, "d").format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      },
    },
    skip: !pastCriteria,
  });

  return (
    <>
      <div className={`${styles.heroContent}`}>
        <div className={`${styles.innerContainer}`}>
          <div className={`${styles.messageArea}`}>
            <div className={`${styles.priorityMessage}`}>
              <p>
                <span className={`${styles.gradation}`}>狙ったお部屋は逃さない</span>
              </p>
              <p>
                <span className={`${styles.gradation}`}>ダイレクト賃貸検索のエアドア</span>
              </p>
            </div>
            <p className={styles.secondaryMessage}>管理会社直接連携 賃貸ポータルサイト</p>
          </div>
          <div className={`${styles.logoMain}`} />
          <div className={`${styles.messageArea2} is-pc-only`}>
            <div className={`${styles.messageArea2__item} ${styles.building}`}>
              <span className={`${styles.gradation}`}>一次情報から毎日更新</span>
              <span className={` ${styles.blackText}`}>された最新の物件情報</span>
            </div>
            <div className={`${styles.messageArea2__item} ${styles.clock}`}>
              <span className={`${styles.gradation}`}>おとり物件・重複なし</span>
              <span className={`${styles.blackText}`}>の物件のみで探しやすい</span>
            </div>
            <h1 className={`${styles.messageArea2__item} ${styles.calc}`}>
              <span className={`${styles.gradation} `}>仲介手数料無料</span>
              <span className={`${styles.blackText}`}>の物件を多数掲載</span>
            </h1>
          </div>
          <div className={styles.controlAreaWrapper}>
            <div className={styles.controlArea}>
              <div className={`is-sp-only ${styles.aboutLink}`}>
                <span className={styles.label}>初めての方はこちら</span>
                <Link className={styles.innerWrap} href="/about">
                  <div className={styles.innerWrap}>
                    <Image
                      width={12}
                      height={18}
                      src={beginnerMark}
                      alt="初心者マーク"
                      unoptimized
                    />
                  </div>
                  <p className={styles.linkText}>お得でリアルタイムなエアドアって？</p>
                </Link>
              </div>
              <div className={styles.controlAreaLinks}>
                {(newestSavedSearch || newestSearchHistory) && (
                  <>
                    {newestSavedSearch
                      ? pastCriteriaSearchButton(
                          newestSavedSearch,
                          "保存した条件",
                          newRoomCountResult,
                        )
                      : pastCriteriaSearchButton(
                          newestSearchHistory,
                          "前回の条件",
                          newRoomCountResult,
                        )}
                  </>
                )}
                <Link className={`${styles.station} ${styles.control}`} href="/condition/route/">
                  <span>路線・駅から探す</span>
                </Link>
                <Link className={`${styles.area} ${styles.control}`} href="/condition/region/">
                  <span>エリアから探す</span>
                </Link>
              </div>

              <div className={styles.controlAreaSearch}>
                <p className={styles.label}>物件名から探す</p>
                <PropertySearchBar type="top"></PropertySearchBar>
              </div>
            </div>
            <div className={styles.controlArea}>
              <div className={`is-pc-only ${styles.aboutLink}`}>
                <span className={styles.label}>初めての方はこちら</span>
                <Link className={styles.innerWrap} href="/about">
                  <div className={styles.innerWrap}>
                    <div className={styles.imageWrap}>
                      <Image width={27} height={39} src={beginnerMark} alt="初心者マーク" />
                    </div>
                    <span className={styles.linkText}>
                      お得でリアルタイムな
                      <br />
                      エアドアって？
                    </span>
                  </div>
                </Link>
              </div>
              <p className={`is-pc-only ${styles.caution}`}>※東京・神奈川一部エリア先行スタート</p>
            </div>
          </div>
          <div className={`${styles.messageArea2} is-sp-only`}>
            <h3 className={styles.messageArea2__title}>3つのポイント</h3>
            <div className={`${styles.messageArea2__item} ${styles.building}`}>
              <span className={`${styles.gradation}`}>一次情報から毎日更新</span>
              <span className={` ${styles.blackText}`}>された最新の物件情報</span>
            </div>
            <div className={`${styles.messageArea2__item} ${styles.clock}`}>
              <span className={`${styles.gradation}`}>おとり物件・重複なし</span>
              <span className={`${styles.blackText}`}>の物件のみ</span>
            </div>
            <h1 className={`${styles.messageArea2__item} ${styles.calc}`}>
              <span className={`${styles.gradation}`}>仲介手数料無料</span>
              <span className={`${styles.blackText}`}>の物件を多数掲載</span>
            </h1>
            <p className={styles.caution}>※東京・神奈川一部エリア先行スタート</p>
          </div>
        </div>
      </div>
      <div className={styles.carouselItem}>
        <FavoriteRoomCarousel
          title={
            <div className={carouselStyles.titleWrapper}>
              <div className="container is-font-bold">
                <h2>お気に入りにしたお部屋</h2>
              </div>
              <Link className={carouselStyles.readAllLink} href={"/mypage/saved/property"}>
                もっと見る
              </Link>
            </div>
          }
        ></FavoriteRoomCarousel>
      </div>
      <div className={styles.carouselItem}>
        <ViewHistoryCarousel
          title={
            <div className={carouselStyles.titleWrapper}>
              <div className="container is-font-bold">
                <h2>最近見たお部屋</h2>
              </div>
              <Link className={carouselStyles.readAllLink} href={"/mypage/history/property"}>
                もっと見る
              </Link>
            </div>
          }
        ></ViewHistoryCarousel>
      </div>
    </>
  );
};

export default Hero;
